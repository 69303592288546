import React from "react";
import { useEffect, useState } from "react";
import Dispatcher from "./components/Dispatcher";
import { get, post, put } from "./lib/http/Http";
import { useForm } from "react-hook-form";
import * as Sentry from "@sentry/react";

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,

  // matchRoutes is not used in this example, but it is required to be passed to the integration
  matchRoutes,
} from "react-router-dom";

Sentry.init({
  dsn: "https://24335c3205ab3ebc86dc4074bd1e6993@o355390.ingest.us.sentry.io/4506710676733952",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/onfarma\.it/,
    /^https:\/\/www\.onfarma\.it/,
    /^https:\/\/onfarma\.it\//,
    /^https:\/\/www\.onfarma\.it\//,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App({ dataset }) {
  const [formId, setFormId] = useState();
  const [formName, setFormName] = useState();
  const [form, setForm] = useState(null);
  const [formError, setFormError] = useState(null);
  const [fields, setFields] = useState(null);

  const [customerId, setCustomerId] = useState();
  const [defaultValues, setDefaultValues] = useState(null);
  const [existingOnStrapi, setExistingOnStrapi] = useState(null);
  const [formData, setFormData] = useState(null);
  const [dispatcherName, setDispatcherName] = useState(null);
  const [shopifyAuthData, setShopifyAuthData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => setFormData(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // setCustomerId(window.__st?.cid);
  }, []);

  useEffect(() => {
    console.log("WIDGET CARICATO");
  }, []);

  const generate8CharPassword = () => {
    const length = 8;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const shopifyAuthenticate = async () => {
    const data = { ...shopifyAuthData };
    const res = await fetch("/account/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer: {
          email: data.email,
          password: generate8CharPassword(),
        },
        form_type: "customer_login",
        utf8: "✓",
      }),
    }).catch((error) => {
      Sentry.captureException(error);
    });

    if (res.status === 200) {
      window.location.href = "https://www.onfarma.it/account/addresses?l=true";
    }

    if (res.status === 422) {
      setFormError(true);
    }

    if (res.status === 404) {
      setFormError(true);
    }

    if (res.status === 500) {
      setFormError(true);
    }

    if (res.status === 401) {
      setFormError(true);
    }
  };

  const onSubmit = async (_data) => {
    setIsLoading(true);
    const data = {
      ..._data,
      "customer#metafields#customer_fields#tipo_di_cliente": formName,
    };

    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (key.includes("#")) {
        const newKey = key.replace(/#/g, ".");
        data[newKey] = value;
        delete data[key];
      }
    });

    const payload = {
      form: formId,
      payload: { ...data, password: generate8CharPassword() },
    };

    if (existingOnStrapi) {
      put({
        path: `applications/${existingOnStrapi}`,
        data: {
          data: { ...payload },
        },
      })
        .then((response) => {
          window.location.href = window.__st?.cid ? document.referrer : "https://www.onfarma.it/account";
        })
        .catch((error) => {
          console.error("error", error);
          setFormError(true);
          Sentry.captureException(error);
        });
    } else {
      // se non esiste su strapi però dovrei capire se esiste su shopify

      if (customerId) {
        const _application = await post({
          path: `applications`,
          data: {
            data: { ...payload },
          },
        }).catch((error) => {
          console.error("error", error);

          Sentry.captureException(error);
        });
        const _id = _application?.data?.data?.id;

        if (_id) {
          put({
            path: `applications/${_id}`,
            data: {
              data: { ...payload },
            },
          })
            .then((response) => {
              window.location.href = "https://www.onfarma.it/account";
            })
            .catch((error) => {
              console.error("error", error);
              setFormError(true);
              Sentry.captureException(error);
            });
        }
      } else {
        // è una nuova registrazione
        post({
          path: `applications`,
          data: {
            data: {
              ...payload,
              createOnShopify: true,
            },
          },
        })
          .then((response) => {
            window.scrollTo(0, 0);

            setShopifyAuthData(data);
          })
          .catch((error) => {
            console.error("error", error);
            setFormError(true);
            Sentry.captureException(error);
          });
      }
    }
  };

  const retrieveForm = async () => {
    const { data } = await get({
      path: `forms/${formId}?populate=deep`,
    }).catch((error) => {
      Sentry.captureException(error);
    });
    setForm(data.data);
  };

  const populateFields = () => {
    const { attributes } = form;
    const { areas } = attributes;
    const fieldItems = [];

    areas.data.forEach((element) => {
      const blocks = element.attributes.blocks;

      blocks.map((block) => {
        block.fields.map((field) => {
          if (customerId) {
            if (!field.slug.includes("password")) {
              if (field.type !== "checkbox") {
                fieldItems.push({
                  [field.slug]: field,
                });
              }
            }
          } else {
            fieldItems.push({
              [field.slug]: field,
            });
          }
        });
      });
    });

    setFields(fieldItems);
  };

  useEffect(() => {
    if (form) {
      populateFields();
    }
  }, [form, defaultValues]);

  useEffect(() => {
    formId && retrieveForm();
  }, [formId]);

  useEffect(() => {
    if (shopifyAuthData) {
      window.location.href = "/account/login";
    }
  }, [shopifyAuthData]);

  const cleanPhone = (phone) => {
    try {
      return phone.replace(/\D/g, "").slice(-10);
    } catch (err) {
      return phone;
    }
  };

  const retrieveFieldsFromShopify = async () => {
    const { data } = await get({
      path: `applications/shopify/${customerId}`,
    }).catch((error) => {
      Sentry.captureException(error);
    });

    const { metafields, customer } = data;
    let _fields = {
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      phone: cleanPhone(customer.phone),
    };

    metafields.forEach((metafield) => {
      const { key, value, owner_resource, namespace } = metafield;
      let slug = owner_resource + ".metafields." + namespace + "." + key;
      _fields[slug.replace(/\./g, "#")] = value;
    });

    setDispatcherName(
      _fields["customer#metafields#customer_fields#tipo_di_cliente"]
    );

    setDefaultValues(_fields);
  };

  const retrieveFields = async () => {
    console.log("retrieveFields");
    const { data } = await get({
      path: `applications?filters[customerId][$eq]=${customerId}&populate=deep`,
    }).catch((error) => {
      Sentry.captureException(error);
    });

    const info = data.data;

    if (info.length > 0) {
      setExistingOnStrapi(info[0]["id"]);
      const { payload } = info[0]["attributes"];
      const _o = {};

      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (key.includes(".")) {
          const newKey = key.replace(/\./g, "#");
          _o[newKey] = value;
          delete _o[key];
        } else {
          _o[key] = value;
        }
      });

      setDispatcherName(
        _o["customer#metafields#customer_fields#tipo_di_cliente"]
      );

      if (!_o["customer#metafields#customer_fields#tipo_di_cliente"]) {
        retrieveFieldsFromShopify();
      }

      setDefaultValues(_o);
    } else {
      setExistingOnStrapi(null);
      if (customerId) {
        retrieveFieldsFromShopify();
      }
    }
  };

  useEffect(() => {
    if (customerId) {
      console.log("caso 1");
      retrieveFields();
    } else {
      setDefaultValues({});
    }
  }, [customerId]);

  const cleanFormKey = (key) => {
    // remove ALL instances of . with a #
    return key.replace(/\./g, "#");
  };

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (formError) {
      setIsLoading(false);
    }
  }, [formError]);

  // if (shopifyAuthData) {
  //   return (
  //     <>
  //       <div className="cf-react-target">
  //         <div className="cf-form-inner">
  //           <div
  //             style={{
  //               marginTop: "-20px !important",
  //               marginBottom: "20px !important",
  //             }}
  //             className="avviso-reg"
  //             data-cf-field-id="235163"
  //             data-cf-field-type="paragraph"
  //             data-cf-column-width="12"
  //             data-cf-grow="true"
  //             data-cf-field-actions="show_field"
  //             key={"message-ok"}
  //           >
  //             <div
  //               className="cf-field avviso-reg"
  //               data-cf-field-id="235163"
  //               data-cf-field-type="paragraph"
  //               data-cf-grow="true"
  //               style={{
  //                 padding: "20px !important",
  //                 margin: "0px !important",
  //               }}
  //             >
  //               <div
  //                 style={{
  //                   fontSize: "14px",
  //                 }}
  //               >
  //                 <p
  //                   style={{
  //                     textAlign: "center",
  //                     padding: "20px"
  //                   }}
  //                 >
  //                   Grazie per esserti registrato su <b>ONFARMA.it</b>, clicca sul link che segue per accedere alla tua area riservata
  //                   <button
  //                     onClick={() => shopifyAuthenticate()}
  //                     style={{
  //                       textDecoration: "underline",
  //                     }}
  //                   >
  //                     Accedi su <b>ONFARMA.it</b>
  //                   </button>
  //                 </p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  return (
    <div className="cf-react-target">
      <div className="cf-form-inner">
        <Dispatcher
          formId={formId}
          dataset={dataset}
          setFormId={setFormId}
          setFormName={setFormName}
          dispatcherName={dispatcherName}
          customerId={customerId}
        />

        {form?.attributes?.customText && (
          <>
            <div
              style={{
                marginTop: "-20px !important",
                marginBottom: "20px !important",
              }}
              className="avviso-reg"
              data-cf-field-id="235163"
              data-cf-field-type="paragraph"
              data-cf-column-width="12"
              data-cf-grow="true"
              data-cf-field-actions="show_field"
              key={"message-" + form?.id}
            >
              <div
                className="cf-field avviso-reg"
                data-cf-field-id="235163"
                data-cf-field-type="paragraph"
                data-cf-grow="true"
                style={{
                  padding: "20px !important",
                  margin: "0px !important",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: form?.attributes?.customText,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </>
        )}

        {formId && (
          <div className="cf-form-step">
            {fields && (
              <>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {fields.map((field, index) => {
                    const key = cleanFormKey(Object.keys(field)[0]);
                    const value = field[Object.keys(field)[0]];

                    return (
                      <div
                        key={key}
                        className="cf-field-container"
                        data-field-type={value.type}
                        data-field-name={key}
                        data-cf-column-width="12"
                      >
                        <div
                          className="cf-field"
                          data-cf-invalid={errors[key] ? "true" : "false"}
                          style={{
                            display: "flex",
                            marginBottom: "20px",
                            flexDirection:
                              value.type === "checkbox"
                                ? "row-reverse"
                                : "column",
                            alignItems:
                              value.type === "checkbox"
                                ? "baseline"
                                : "flex-start",
                            justifyContent: "flex-end",
                          }}
                        >
                          <label
                            htmlFor={key}
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {value.label.toUpperCase()}{" "}
                            {value.required && <span>*</span>}
                            {value.defaultValue && (
                              <>
                                {value.type === "checkbox" && (
                                  <small>{value.defaultValue}</small>
                                )}
                              </>
                            )}
                          </label>
                          <input
                            style={{
                              width:
                                value.type !== "checkbox" ? "100%" : "auto",
                              marginRight:
                                value.type === "checkbox" ? "10px" : 0,
                              marginBottom: "10px",
                            }}
                            {...register(key, {
                              validate: (v) => {
                                if (value.required) {
                                  let isValid = v;
                                  try {
                                    const _dependentFields =
                                      value.dependentFields
                                        ? value.dependentFields.split(",")
                                        : null;

                                    if (_dependentFields) {
                                      if (_dependentFields.length > 0) {
                                        let otherFieldsAreValid = true;
                                        _dependentFields.forEach((df) => {
                                          const _key = cleanFormKey(df);
                                          const _value = formData[_key];
                                          otherFieldsAreValid = _value;

                                          return !!_value;
                                        });

                                        if (otherFieldsAreValid) {
                                          isValid = true;
                                        } else {
                                          isValid = value.required ? !!v : true;
                                        }
                                      }
                                    } else {
                                      const checkIfThisFieldIsEmpty = () => {
                                        return !!v;
                                      };

                                      isValid = value.required
                                        ? checkIfThisFieldIsEmpty()
                                        : false;
                                    }
                                  } catch (err) {
                                    console.log(err);
                                  }

                                  return isValid;
                                } else {
                                  return true;
                                }
                              },
                              minLength: value.minLength,
                              maxLength: value.maxLength,
                              min: value.min,
                              max: value.max,
                            })}
                            id={key}
                            autoComplete="new-password"
                            data-cf-input
                            data-cf-invalid={errors[key] ? "true" : "false"}
                            name={key}
                            type={value.type}
                          />
                          {value.defaultValue && (
                            <>
                              {value.type !== "checkbox" && (
                                <small>{value.defaultValue}</small>
                              )}
                            </>
                          )}
                          {errors[key] && (
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              Questo campo non può essere lasciato vuoto
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <input
                    type="submit"
                    disabled={isLoading}
                    style={{
                      width: "100%",
                    }}
                  />
                </form>
                {formError && (
                  <div className="cf-form-error">
                    <div className="cf-form-error-message">
                      <span>
                        Si è verificato un errore, riprova più tardi. Potrebbe
                        già esistere un account con questa email o con questo
                        numero di telefono.
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
